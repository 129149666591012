
import React, { Fragment } from 'react';
import {sendFile} from '../controllers/MainController.js'
import SaveAlt from '@material-ui/icons/SaveAlt';
import '../css/App.css'
import '../css/ota.css'
import { ClipLoader } from 'react-spinners';
import {DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
class OTA extends React.Component{
    constructor(){
        super();
        this.state={
            file:null,
            type:null,
            desc:'',
            fileName:'Choose a file',
            message:'',
            loading:false,
            dialogOpen:false,
            buildNum:''
        }
    }
    //Checks if everything is filled out
    validateForm(){
        //Receives file
        var file = this.state.file;
        var desc = this.state.desc;
        var type = this.state.type;
        var buildNum = this.state.buildNum;
        if(file && desc!='' && type && buildNum!=''){
            this.setState({dialogOpen:true})
            return;
        }
        this.setState({message:'Please fill out the form'})
    }
    //Sends data to the main controller
    async handleSubmit(file,desc,type,buildNum){

        this.setState({loading:true})
        console.log(buildNum);
        //Waits for data to send or receive an error
        var send = await sendFile(file,desc,type,buildNum).catch(error=>console.log(error));

        //Error checking
        try{
            let success = send.data.success;
            if(success){
                this.setState({message:"Uploaded Successfully"});
            }
            else{
                this.setState({message:"Invalid request"});
            }
        }
        catch(error){
            console.log(error);
            //Usually called if file is too large
            this.setState({message:"Error occured. File may be too large"});
        }

        this.setState({loading:false});
    }
    render(){
        return(
            <div className='page'>
                <div className='block'>
                    <h2> Upload</h2>
                    <hr/>
                    <Fragment style={{overflow:'hidden'}} >
                        <Dialog classes={{scrollBody:'dialog-outer'}} open={this.state.dialogOpen}>
                            <DialogContent id='dialog'>
                                Are you sure you would like to choose the {this.state.type}
                                <div id='dialog-buttons'>
                                    <input
                                        className ='dialog-button'
                                        type='button'
                                        value='Yes'
                                        onClick={()=>{
                                            this.setState({dialogOpen:false})
                                            this.handleSubmit(this.state.file,this.state.desc,this.state.type,this.state.buildNum);
                                        }
                                        }
                                    />
                                    <input
                                        className ='dialog-button'
                                        type='button'
                                        value='No'
                                        onClick={()=>this.setState({dialogOpen:false})}
                                    />
                                </div>
                            </DialogContent>
                        </Dialog>
                    </Fragment>
                    {this.state.message == '' ? <span className='message'> &nbsp;</span>: <span className='message'>{this.state.message}</span>}
                    <textarea
                        type='text'
                        id='desc'
                        className='ota-element'
                        onChange={(event)=>this.setState({desc:event.target.value})}
                        placeholder='Leave a description about your file'
                    />
                    <input type='text' id='build-num' className='ota-element'
                           placeholder = 'Enter build number'
                           onChange={(event)=>this.setState({buildNum:event.target.value})}
                    />
                    <select
                        id='select-glass'
                        placeholder = 'Which glass type'
                        className='ota-element'
                        onChange={(event)=>this.setState({type:event.target.value})}
                    >
                        <option value={null} disabled  selected>Select your glasses</option>
                        <option value='x1a'>X1A</option>
                        <option value='x2'>X2</option>
                    </select>

                    <p id='fileName'>{this.state.fileName}</p>

                    <input
                        type="file"
                        name="file"
                        id="file"
                        class="inputfile"
                        accept='.zip'
                        onChange={(event)=> this.setState({file:event.target.files,fileName:event.target.files[0].name})}
                    />
                    <label for="file"><SaveAlt/></label>
                    <div class='loader'>
                        <ClipLoader
                            ref={this.cliploader}
                            size={25} loading={true}
                            color= '#000000'
                            loading={this.state.loading}
                        />
                    </div>

                    <input
                        id='file-submit'
                        type='button'
                        className='submit-button'
                        value='Send File'
                        onClick={this.validateForm.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
export default OTA;


import React from 'react';
import logo from './logo.svg';
import {BrowserRouter as Router,Route,Redirect} from "react-router-dom";
import history from 'history/createBrowserHistory'
import './css/App.css';
import Login from './views/Login.js';
import OTA from './views/OTA.js';
class App extends React.Component {
    constructor(){
        super();
    }
    render(){

        return (
            <div className="App">
                <Router history={history}>
                    <Route exact path='/ota' component={OTA}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path="/" render={() =>
                        <Redirect to='/login'/>
                    }/>
                </Router>
            </div>
        );
    }

}
export default App;

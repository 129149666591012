
import React from 'react';
import {handleLogin,sendFile} from '../controllers/MainController.js'
import '../css/App.css';
import '../css/login.css';
import { ClipLoader } from 'react-spinners';
class Login extends React.Component{
    constructor(){
        super();
        this.state={
            username:'',
            password:'',
            loading:false,
            message:''
        }
    }
    async handleSubmit(event){
        event.preventDefault();
        var username = this.state.username;
        var password = this.state.password;
        if(!(username && password)){
            return;

        }
        this.setState({loading:true,message:''})



        //Error checking
        try{
            //Waits for data to send or receive an error
            var send = await handleLogin(username,password).catch(error=>console.log(error));
            var success = send.data.success;
            var status = send.data.status;

            if(success){
                this.setState({error:""});
                window.sessionStorage.setItem('loggedIn',true)
                this.props.history.push("/ota");
            }
            else{
                this.setState({message:"Incorrect username or password"});
            }
        }
        catch(error){
            //Usually called if file is too large
            this.setState({message:"Error: " + status});
        }
        this.setState({loading:false});

    }
    render(){
        //dont go to login if already logged in
        if(window.sessionStorage.getItem('loggedIn')){
            this.props.history.push("/ota");
        }

        return(
            <div className='page'>
                <div className='block'>
                    <h2> Sign in</h2>
                    <hr/>
                    {this.state.message == '' ? <span className='message'> &nbsp;</span>: <span className='message'>{this.state.message}</span>}

                    <input id='username' className='login-element' placeholder='Username' type='text' onChange={(event)=>this.setState({username:event.target.value})}/>
                    <input className='login-element' type='password' placeholder='Password' onChange={(event)=>this.setState({password:event.target.value})}/>




                    <div className='loader'>
                        <ClipLoader
                            ref={this.cliploader}
                            size={25} loading={true}
                            color= '#000000'
                            loading={this.state.loading}
                        />
                    </div>




                    <input type='button' className='submit-button' value='Log in' onClick={this.handleSubmit.bind(this)}/>
                </div>

            </div>
        );
    }
}
export default Login;

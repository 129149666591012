import axios from 'axios';

export const handleLogin=async(username,password)=>{
    var handleLoginPromise = new Promise((resolve,reject)=>{

        var formData = new FormData();
        formData.set("username",username);
        formData.set("password",password);

        // axios.post('http://127.0.0.1:3024/api/test/login',formData)
        //     .then(res => {
        //         resolve(res);
        //     })
        //     .catch(error=>{
        //         reject(error)
        //     })

        axios.post('https://ota.thirdeyegen.com/api/test/login',formData)
          .then(res => {
            resolve(res);
          })
          .catch(error=>{
            reject(error)
          })
    })


    return handleLoginPromise;

}


export const sendFile= async(file,description,typeOfDevice,buildNum)=>{
    var sendFilePromise = new Promise((resolve,reject)=>{

        console.log(buildNum);
        var formData = new FormData();
        formData.set("file",file[0]);
        formData.set("desc",description);
        formData.set("type",typeOfDevice);
        formData.set("buildNum",buildNum);

        // axios.post('http://127.0.0.1:3024/api/test/sendZip',formData)
        //     .then(res => {
        //         console.log(res);
        //         resolve(res);
        //     }).catch(error=>{
        //     console.log(error);
        //     reject(error);
        // })

          axios.post('https://ota.thirdeyegen.com/api/test/sendZip',formData)
          .then(res => {
            console.log(res);
            resolve(res);
          }).catch(error=>{
            console.log(error);
            reject(error);
        })


    })


    return sendFilePromise;
}












